<template>
  <v-layout justify-center align-center fill-height class="full-h">
    <v-icon color="primaryred" x-large left> mdi-alert-octagon </v-icon>
    <p class="mb-0 text-h5" color="error">
      {{ $t("You have no permission to view this page") }}
    </p>
  </v-layout>
</template>
<script>
export default {
  created() {
    const toaster = {
      message: this.$t("You have no permission to view this page"),
      timeout: false,
      type: "ERROR",
      toastClassName: ["toaster-color-error", "toaster-layout"],
      bodyClassName: ["toaster-text-layout"],
      icon: "mdi-alert-octagon",
    };
    this.$store.dispatch("setToaster", toaster);
  },
};
</script><style>
.full-h {
  height: 86vh;
}
</style>

